@import url('https://fonts.googleapis.com/css2?family=Sora:wght@600&display=swap');

p, h1 {
  font-family: 'Sora', sans-serif;
}

nav {
  position: absolute;
}

body {
  background-image: linear-gradient(#f5b587, white);
  background-attachment:fixed;
  height:100%;
  overflow-x: hidden;
}

.navbar-default {
  background: none;
}

Link:hover {
  text-decoration: none;
}

.center-tag {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 40%;
}

/* Animation to slide entries up */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide {
  animation: 1s ease-out 0s 1 slideInFromRight;
}

.about-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height:200px; 
}

.col-xs-6 {
  text-align: center;
  right: 10%;
  width: 60%;
}

.center2 {
  margin: 0 auto;
}

.book-img {
  margin-left: 15%;
  width: 40%;
}

.book-single {
  width: 25%;
  margin-left: 37.5%;
}

.book-title {
  text-align: center;
}

.flexie {
  margin-left: calc(50% - 45px);
}

.no-flex {
  display: block;
}

.cent-img {
  margin-left: 25%;
}

.img-two {
  padding-top: 20px;
}

.story-row {
  position: absolute;
  top: 40%;
}

.medium-link, .medium-link:hover {
  color: black;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .center-tag {
    position: relative;
  }

  .row {
    display: block;
  }

  .col-8 {
    margin: 0 auto;
  }

  .story-row {
    position: relative;
  }

  p, h3 {
    color: black;
    text-align: center;
  }

  .cent-img {
    width: 100%;
  }

  .col-3 {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .book-img {
    width: 50%;
    margin-left: 25%;
    padding-top: 2%;
  }

  .nav-link:hover {
    text-decoration: underline;
  }
}